
import { defineComponent, ref, computed, onMounted, onBeforeMount, onUpdated  } from "vue";
import { useStore } from "vuex"
import useLanguagev2 from '@/composables/v2/useLanguage';
import { useI18n } from "vue-i18n";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  components: {
    Editor,
  },
  props: {
    fieldName: String,
    fieldValue: [String, Object],
    fieldType: String,
    currentData: String,
    currentModalId: String,
    previousModalId: String
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { getAvailableLanguages, languageOptions, languageModal, modalRef1, componentLoading } = useLanguagev2();
    const store = useStore();
    getAvailableLanguages(props.fieldValue)

    const updatenow = (title, data, type) => {
      const method = "indirect"
      emit('gateway', {method: method, data: {key: props.fieldName, value: data, validation: 'json', type: props.fieldType}})
    }

    onMounted(() => {
      languageModal(props.previousModalId);
    })

    return {
      languageOptions,
      store,
      t,
      updatenow,
      modalRef1,
      componentLoading
    }
   }
});
