<template>
  <div class="card shadow-sm mb-5 mb-xxl-8" v-loading="componentLoading">
    <div class="card-header">
      <div class="card-title m-0 flex-fill cursor-pointer" role="button" data-bs-toggle="collapse" :data-bs-target="'#es_card_'+ componentRegisterId" :aria-expanded="componentData.toggle == 'open' ? true : false" :aria-controls="'es_card_'+ componentRegisterId">
          <h3 v-if="componentStatus" class="fw-bolder m-0">{{ componentData.structure.title }}</h3>
      </div>
      <div class="card-toolbar" v-if="componentData.status && componentData.toolbar.init">
        <!-- <ToolbarFilter v-if="componentData.toolbar.filter"
          currentData="allPicklistLines" 
          currentSetting="sale_statuses"
          :dispatchActions='{ init: "currentShipmentLinesFilter", save: "SET_SHIPMENT_LINES_FILTER" }'
          :key="reloadAmount"
          @reload="reloadComponent"
        /> -->
        <div class="me-0" v-if="componentData.toolbar.actions.init">
          <button
            class="btn btn-sm btn-icon btn-bg-light btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-2 svg-icon-gray-500">
              <Icon name="Dots.svg"/>
            </span>
          </button>
          <ToolbarActions
            :title="componentData.toolbar.actions.title" 
            :menu="componentData.toolbar.actions.menu"
            @reload="reloadComponent"
          />
        </div>
      </div>
    </div>
    <div class="card-content collapse" :class="(componentData.toggle == 'open') ? 'show' : ''" :id="'es_card_'+ componentRegisterId">
      <form @submit.prevent="saveComponent()">
        <div class="card-body p-8 form-group row">
          <template v-if="componentType == 'form' && componentData.status">
            <div :class="'col-' + field.column" v-for="field in componentData.data" :key="field.name" :hidden="field.hidden">
              <FormTypes 
                :key="componentData.data"
                :field-type="field.type" 
                :field-label="field.label" 
                :field-validation="field.validation" 
                :field-options="field.options" 
                :field-value="field.defaultValue" 
                :field-value-display="field.defaultValueDisplay"
                :field-disabled="field.disabled" 
                :field-name="field.name" 
                :field-addon="field.addon" 
                :field-transfer-fill="field.transferFill"
                :field-settings="field.settings"
                :current-data="field.currentData"
                @gateway="updateComponent" 
                @languageModal="initLanguageModal"
              />
            </div>
          </template> 
          <template v-if="componentType == 'table' && componentData.status">
            <Table :processing-data="componentData" @unique="renderModal" @reload="reloadComponent"/>
          </template>

          <ModalLanguage
            v-if="renderAmount" :key="renderAmount"
            :fieldName="fieldName"
            :fieldValue="fieldValue"
            :fieldType="fieldType"
            :currentModalId="modalId + '_lang'"
            :previousModalId="modalId"
            :currentData="fieldCurrentData"
            @gateway="updateComponent"
          />
        </div>
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            :data-kt-indicator="!submitLoadingComponent ? null : 'on'"
            class="btn btn-lg btn-primary"
            type="submit"
            :disabled="!submitButtonComponent"
          >
          <!-- ref="formRef" -->
            <span v-if="!submitLoadingComponent" class="indicator-label">
              {{ $t("buttons.Submit") }}
            </span>
            <span v-if="submitLoadingComponent" class="indicator-progress">
              {{ $t("buttons.Loading") }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeMount } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent.ts";
import Table from '@/components/tables/v2/Table.vue';
import Icon from '@/components/icons/Icon.vue';
import FormTypes from '@/components/forms/v2/Types.vue';
import useComponentv2 from '@/composables/v2/useComponent';
import ModalLanguage from "@/components/modals/default/v2/LanguageModal.vue";
import useLanguage from '@/composables/v2/useLanguage';
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";

export default defineComponent({
  name: '',
  components: {
    FormTypes,
    Table,
    ModalLanguage,
    Icon,
    ToolbarActions
  },
  props: {
    componentName: String,
    displayMethod: String,
    componentType: String,
    dispatchActions: Object,
    componentReload: Boolean,
    componentRedirect: Object,
    currentData: String,
    componentFilter: Object,
    componentToolbar: Object,
    currentId: String
  },
  setup(props) {
    const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent } = useComponentv2();
    const { renderAmount, fieldName, fieldValue, fieldType, fieldCurrentData, initLanguageModal } = useLanguage();

    initComponent({
      componentName: props.componentName,
      displayMethod: props.displayMethod,
      componentType: props.componentType,
      dispatchActions: props.dispatchActions,
      componentReload: props.componentReload,
      componentRedirect: props.componentRedirect,
      currentData: props.currentData,
      componentFilter: props.componentFilter,
      componentToolbar: props.componentToolbar,
      currentId: props.currentId,
      routeId: props.currentId
    });
      
    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      reloadComponent,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      renderAmount,
      fieldName,
      fieldValue,
      fieldType,
      initLanguageModal,
      fieldCurrentData
    }
  }
});
</script>